<!--  -->
<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-button type="primary" v-if="tableData && tableData.length>0" @click="exportXcle" icon="el-icon-download">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablebox">
      <el-table
        :data="tableData"
        style="width: 100%"
        id="exportTable"
        :header-cell-style="{fontWeight: '700', fontSize: '14px'}"
        :index="indexMethod">
        <el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
        <el-table-column prop="communityName" label="社区" align="center"></el-table-column>
        <el-table-column prop="orgName" label="党组织名称" align="center"></el-table-column>
        <el-table-column prop="branchName" label="党支部名称" align="center"></el-table-column>
        <el-table-column prop="count" label="党员社员人数" align="center"></el-table-column>
        <el-table-column prop="actTimes" label="任务场次" align="center"></el-table-column>
        <el-table-column prop="cpcActTimes" label="党员参与场次" align="center"></el-table-column>
        <el-table-column prop="actUserCount" label="任务参与人次" align="center"></el-table-column>
        <el-table-column prop="cpcActUserCount" label="党员参与人次" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRow">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { volCpcSummaryList, exportExcel } from '@/api/reportStatistics'
import {communityOfUser} from "@/api/system";
export default {
  data () {
    return {
      tableData: [],
      currentPage: 1,
      totalRow: 0,
      pageSize: 10,
    };
  },

  components: {},

  computed: {},

  mounted(){
    this.onSearch();
  },

  methods: {
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      volCpcSummaryList({
        pageSize: this.pageSize,
        page: this.currentPage,
      }).then(res => {
        if(res.result == 200) {
          this.tableData = res.detail.list;
          this.totalRow = res.detail.totalRow;
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //自定义序号
    indexMethod(index) {
      return (this.currentPage - 1)*this.pageSize + (index+1);
    },
    exportXcle() {
      exportExcel({
        type: 5,
      }).then(res => {
        const content = res;
        const blob = new Blob([content]) //构造一个blob对象来处理数据
        const fileName =  '社区党员社员情况.xlsx';
        const link = document.createElement('a') //创建a标签
        link.download = fileName //a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click() //执行下载
        URL.revokeObjectURL(link.href) //释放url
        document.body.removeChild(link) //释放标签
      })
    },
  }
}

</script>
<style lang='scss' scoped>
.searchbox {
  margin: 20px 10px;
}
.pagebox{
  margin: 20px 0;
  text-align: right;
}
</style>